<template>
  <div>
    <dialDelivery
      v-model="dialog"
      :to-edit="toEdit"
      ref="dialdelivery"
      :order="order.pk"
      :cartlist="cartlist"
      :partialDelivery="partialDelivery"
      @done="getCartlist"
    />
    <i-card-list
      v-if="deliveryBtn.view"
      class="mt-2 px-2"
      :title="$t(partialDelivery ? 'partial' : 'delivery')"
      :headers="headers"
      app="sale"
      api="sale.delivery"
      :method="'show'"
      :requestOpts="{ pk: order.pk }"
      :opt="{ order: true }"
      :reload.sync="reload"
      @click:create="deliveryBtn.add ? open() : ''"
      @click:edit="deliveryBtn.edit ? open($event) : ''"
      :dontCreate="!deliveryBtn.add || !partialDelivery"
      :dontRemove="!deliveryBtn.delete || !partialDelivery"
    >
    </i-card-list>
    <br /><br />
    <v-list dense v-if="deliveryBtn.orderproduct">
      <v-list-group
        v-for="(product, key) in cartlist"
        :key="`prouct-${key}`"
        :style="{
          'border-top':
            key !== 0 ? '' : `2px solid ${$vuetify.theme.themes.light.primary}`,
          'border-bottom': `2px solid ${$vuetify.theme.themes.light.primary}`,
          'border-left': `2px solid ${$vuetify.theme.themes.light.primary}`,
          'border-right': `2px solid ${$vuetify.theme.themes.light.primary}`
        }"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-row>
              <v-col cols="6" sm="4" lg="3" v-if="product.code !== ''">
                <span class="primary--text body-2 font-weight-black">
                  {{ $tc('code', 2) }} : <br />
                </span>
                <span class="primary--text body-2 front-weight-black">
                  {{ product.code }}
                </span>
              </v-col>
              <v-col cols="6" sm="4" lg="3">
                <span class="primary--text body-2 font-weight-black">
                  {{ $tc('product', 1) }} : <br />
                </span>
                <span class="primary--text body-2 front-weight-black">
                  {{ product.own_prod }}
                </span>
              </v-col>
              <v-col cols="6" sm="4" lg="3">
                <span class="primary--text body-2 font-weight-black">
                  {{ $tc('amount', 2) }} : <br />
                </span>
                <span class="primary--text body-2 front-weight-black">
                  {{ product.quantity }}
                </span>
              </v-col>
              <v-col cols="6" sm="4" lg="3">
                <span class="primary--text body-2 font-weight-black">
                  {{ $tc('pending_quantity', 1) }} : <br />
                </span>
                <span class="primary--text body-2 front-weight-black">
                  {{ Number(product.pending).toFixed(2) }}
                </span>
              </v-col>
              <v-col cols="6" sm="4" lg="3">
                <span class="primary--text body-2 font-weight-black">
                  {{ $tc('price', 1) }} : <br />
                </span>
                <span class="primary--text body-2 front-weight-black">
                  <i-money v-model="product.price" mode="text" />
                </span>
              </v-col>
              <v-col cols="6" sm="4" lg="3">
                <span class="primary--text body-2 font-weight-black">
                  {{ $tc('subtotal', 1) }} : <br />
                </span>
                <span class="primary--text body-2 front-weight-black">
                  <i-money
                    :value="Number(product.subtotal) + Number(product.discount)"
                    mode="text"
                  />
                </span>
              </v-col>
              <v-col
                cols="6"
                sm="4"
                lg="3"
                class="red--text"
                v-if="product.discount > 0"
              >
                <span class="primary--text body-2 font-weight-black">
                  {{ $tc('discount', 1) }} : <br />
                </span>
                <span class="primary--text body-2 front-weight-black">
                  <i-money v-model="product.discount" mode="text" />
                </span>
              </v-col>
              <v-col cols="6" sm="4" lg="3">
                <span class="primary--text body-2 font-weight-black">
                  {{ $tc('tax', 1) }} : <br />
                </span>
                <span class="primary--text body-2 front-weight-black">
                  <i-money v-model="product.tax" mode="text" />
                </span>
              </v-col>
              <v-col cols="6" sm="4" lg="3">
                <span class="primary--text body-2 font-weight-black">
                  {{ $tc('total', 1) }} : <br />
                </span>
                <span class="primary--text body-2 front-weight-black">
                  <i-money v-model="product.total" mode="text" />
                </span>
              </v-col>
            </v-row>
          </v-list-item-content>
        </template>
      </v-list-group>
    </v-list>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialDelivery from './dialDelivery.vue'
export default {
  components: {
    dialDelivery
  },
  props: {
    order: {
      required: true,
      type: Object
    },
    balance: {
      type: [String, Number]
    },
    customer: {
      required: false,
      type: [Object]
    },
    partialDelivery: {
      type: Boolean
    }
  },
  data: () => ({
    loading: false,
    reload: false,
    dialog: false,
    dialog2: false,
    toEdit: null,
    cartlist: []
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t('date'),
          value: 'delivery_date'
        },
        {
          text: this.$t('given_to'),
          value: 'given_to'
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    deliveryBtn() {
      return {
        add: this.getPermissions([`sales.add_delivery`]) || this.isAdmin,
        delete: this.getPermissions([`sales.delete_delivery`]) || this.isAdmin,
        edit: this.getPermissions([`sales.change_delivery`]) || this.isAdmin,
        view: this.getPermissions([`sales.view_delivery`]) || this.isAdmin,
        orderproduct:
          this.getPermissions([`sales.view_orderproduct`]) || this.isAdmin
      }
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    },
    async getCartlist() {
      const cart = await this.$api.sale.product.show({
        pk: this.order.pk,
        opt: {
          params: {
            order: true
          }
        }
      })
      this.cartlist = cart.data
    }
  },
  mounted() {
    this.getCartlist()
  }
}
</script>
