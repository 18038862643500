<template>
  <v-container fluid>
    <v-card v-if="invoice != undefined">
      <v-card-title
        :class="{
          'primary--text': true,
          'font-weight-bold': true,
          headline: !$vuetify.breakpoint.xsOnly,
          title: $vuetify.breakpoint.xsOnly,
          'pb-1': true
        }"
      >
        <v-chip
          v-if="invoice != undefined"
          x-small
          class="mr-3"
          :color="
            invoice.status === 'paid'
              ? 'success'
              : invoice.status === 'canceled'
              ? 'red'
              : 'info'
          "
        >
          {{ $tc(invoice.status, 2) }}
        </v-chip>
        {{ $t('invoice') }} #{{ invoice.invoice_number }}
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <i-btn
              :title="$t('print', { value: $tc('invoice', 1) })"
              small
              v-on="on"
              color="error"
              classes="mx-1"
              outlined
              icon="fa-print"
            >
            </i-btn>
          </template>
          <!-- @click="getPdf()" -->
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col
            v-if="invoice.extraInformation"
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              outlined
              name="customer"
              disabled
              :value="invoice.extraInformation.customer"
              readonly
              :label="$tc('customerCompany', 1)"
            />
          </v-col>
          <v-col
            v-if="invoice.extraInformation"
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              outlined
              name="contact"
              disabled
              :value="invoice.extraInformation.contact"
              readonly
              :label="$tc('contact', 1)"
            />
          </v-col>
          <v-col
            v-if="invoice.extraInformation"
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              outlined
              name="phone"
              disabled
              :value="invoice.extraInformation.phone"
              readonly
              :label="$tc('phone', 1)"
            />
          </v-col>
          <v-col
            v-if="invoice.extraInformation"
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              outlined
              name="email"
              disabled
              :value="invoice.extraInformation.email"
              readonly
              :label="$tc('email', 1)"
            />
          </v-col>
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              outlined
              name="date"
              disabled
              :value="dateFormat(invoice.date)"
              prepend-icon="fa-calendar"
              readonly
              :label="$tc('date', 1)"
            />
          </v-col>
          <v-col
            v-if="invoice.extraInformation"
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              outlined
              :name="'SHIP'"
              disabled
              :value="invoice.extraInformation.SHIP"
              readonly
              :label="$tc('customerAddress', 1)"
            />
          </v-col>
        </v-row>
        <v-list style="margin-top: -0.3rem" dense class="py-0">
          <v-list-group v-if="invoice.extraInformation" @click.stop="">
            <v-icon slot="appendIcon" size="12">fas fa-arrow-down</v-icon>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="primary--text subtitle-2 font-weight-black">{{
                    $tc('product', 2)
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              dense
              v-for="param in invoice.extraInformation.products"
              :key="'param-' + param.code"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text"
                  style="font-size: 12px; font-weight: 400"
                >
                  <b>&middot;</b>
                  <span class="font-weight-black">{{
                    `${param.own_prod}:`
                  }}</span>
                  <br />
                  <i-money mode="text" :value="param.price">
                    <span class="font-weight-black">{{
                      `${$tc('price', 1)}:`
                    }}</span>
                  </i-money>
                  <i-money mode="text" :value="param.tax">
                    <span class="font-weight-black">{{
                      `${$tc('tax', 1)}:`
                    }}</span>
                  </i-money>
                  <br />
                  <i-money mode="text" :value="param.subtotal">
                    <span class="font-weight-black">{{
                      `${$tc('subtotal', 1)}:`
                    }}</span>
                  </i-money>
                  <i-money mode="text" :value="param.total">
                    <span class="font-weight-black">{{
                      `${$tc('total', 1)}:`
                    }}</span>
                  </i-money>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-divider class="mb-3"></v-divider>
        <v-row
          no-gutters
          class="text-center body-2 font-weight-black primary--text pr-4"
        >
          <v-col cols="12" sm="6" md="3">
            <i-money
              class="headline font-weight-black"
              mode="text"
              :value="invoice.subtotal"
              :label="`${$t('subtotal')}:`"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <i-money
              mode="text"
              :label="$t('tax')"
              :value="invoice.tax"
              class="font-weight-black headline"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <i-money
              class="headline font-weight-black"
              mode="text"
              :value="invoice.total"
              :label="`${$t('total')}:`"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <i-money
              class="headline font-weight-black"
              mode="text"
              :value="invoice.balance"
              :label="`${$t('balance')}:`"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <br />
    <v-row v-if="invoice != undefined">
      <v-col col="12">
        <payments
          v-if="invoice.pk !== ''"
          @createdPayment="getInvoices()"
          @changeMode="$emit('changeMode', $event)"
          :balance="invoice.balance"
          :invoice="invoice.pk"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import payments from '../../components/invoice/payments.vue'
export default {
  components: {
    payments
  },
  props: {
    order: {
      type: Number
    }
  },
  data() {
    return {
      invoice: ''
    }
  },
  methods: {
    getInvoices() {
      this.loading = true
      this.$api.invoice
        .show({
          pk: this.order,
          opt: {
            params: {
              order: true
            }
          }
        })
        .then((res) => {
          this.invoice = res.data
        })
        .catch((error) => {
          //
        })
        .finally(() => (this.loading = false))
    }
  },
  mounted() {
    this.getInvoices()
  }
}
</script>
