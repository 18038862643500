<template>
  <v-card class="infocard">
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent="handleSubmit(submit)">
        <v-card-title
          :class="{
            'primary--text': true,
            'font-weight-bold': true,
            headline: !$vuetify.breakpoint.xsOnly,
            title: $vuetify.breakpoint.xsOnly,
            'pb-1': true
          }"
        >
          <v-row no-gutters>
            <v-col cols="4" sm="2" class="pt-0 pb-3">
              <v-tooltip top v-if="mode === 1 && balance > 0">
                <template v-slot:activator="{ on }">
                  <i-btn
                    v-if="paymentBtn.add"
                    :title="$t('create', { model: $tc('payment', 1) })"
                    outlined
                    @click="add()"
                    v-on="on"
                    :small="$vuetify.breakpoint.smAndDown"
                    color="secondary"
                    icon="fa-plus"
                  >
                  </i-btn>
                </template>
              </v-tooltip>
              <v-tooltip top v-if="mode === 1 && payments.length > 0">
                <template v-slot:activator="{ on }">
                  <i-btn
                    :title="$tc('history', 1, { model: $tc('payment', 1) })"
                    outlined
                    class="ml-3"
                    :small="$vuetify.breakpoint.smAndDown"
                    v-on="on"
                    color="error"
                    icon="fa-print"
                  >
                  </i-btn>
                </template>
                <!-- @click="getPdf()" -->
              </v-tooltip>
            </v-col>
            <v-col cols="8" sm="10" class="pl-3 pt-0 pb-3">
              <span
                :class="{
                  headline: !$vuetify.breakpoint.xsOnly,
                  'subtitle-1': $vuetify.breakpoint.xsOnly,
                  'font-weight-black': true
                }"
              >
                {{ $tc('payment', 2) }}
              </span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row v-if="mode === 1">
            <v-col
              v-for="payment in payments"
              :key="`payment-${payment.pk}`"
              cols="12"
              sm="6"
              md="4"
              :class="{
                'font-weight-black': true,
                'subtitle-1': !$vuetify.breakpoint.xsOnly,
                'subtitle-2': $vuetify.breakpoint.xsOnly,
                unableProd: payment.canceled,
                infocard: true,
                'text-decoration-line-through': payment.canceled,
                'red--text': payment.canceled
              }"
            >
              <v-row no-gutters justify="end" v-if="!payment.canceled">
                <v-col cols="6">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <i-btn
                        :title="$tc('print', 1, { value: $tc('payment', 1) })"
                        outlined
                        color="info"
                        v-on="on"
                        :small="$vuetify.breakpoint.smAndDown"
                        icon="fa-print"
                      >
                      </i-btn>
                    </template>
                  </v-tooltip>
                  <v-tooltip top>
                    <!-- @click="getPdf()" -->
                    <template v-slot:activator="{ on }">
                      <i-btn
                        v-if="paymentBtn.edit"
                        :title="
                          $t('cancel_specific', { value: $tc('payment', 1) })
                        "
                        outlined
                        color="error"
                        v-on="on"
                        :small="$vuetify.breakpoint.smAndDown"
                        @click="canceledPayment(payment.pk, payment)"
                        icon="fa-times"
                      >
                      </i-btn>
                    </template>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="3" sm="3" order="1" class="primary--text">
                  <span class="subtitle-1 font-weight-bold">
                    {{ $tc('payment', 1) }}:
                  </span>
                  <br />
                  {{ payment.payment_number }}
                </v-col>
                <v-col cols="4" sm="4" order="2" class="primary--text">
                  <span class="subtitle-1 font-weight-bold">
                    {{ $t('paymenttype') }}:
                  </span>
                  <br />
                  {{ payment.showType }}
                </v-col>
                <v-col
                  cols="5"
                  sm="5"
                  order="3"
                  class="primary--text text-center"
                >
                  <span class="subtitle-1 font-weight-bold">
                    {{ $tc('amount', 2) }}:
                  </span>
                  <br />
                  <i-money
                    :value="payment.amount"
                    class="primary--text"
                    mode="text"
                  />
                </v-col>
                <v-col cols="6" order="5" class="subtitle-2 grey--text">
                  {{ $t('invoice') }} #{{ payment.invoice_number }}
                </v-col>
                <v-col cols="6" order="4" class="subtitle-2 grey--text">
                  {{ $t('date') }} {{ dateFormat(payment.date) }}
                </v-col>
                <v-col
                  v-if="payment.reference !== null"
                  cols="12"
                  order="6"
                  class="subtitle-2 grey--text"
                >
                  {{ $t('reference') }}: {{ payment.reference }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-if="mode === 2"
            class="ml-0"
            style="border: 1px dashed var(--primary)"
          >
            <v-col cols="4" md="2">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <i-btn
                    v-if="paymentBtn.edit"
                    :title="$t('back')"
                    outlined
                    @click.stop="mode = 1"
                    small
                    v-on="on"
                    color="error"
                    icon="fa-chevron-left"
                  >
                  </i-btn>
                </template>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <i-btn
                    v-if="paymentBtn.edit"
                    :title="$tc('save', 1)"
                    outlined
                    small
                    v-on="on"
                    @click="submit"
                    :loading="load"
                    color="primary"
                    icon="fa-save"
                  >
                  </i-btn>
                </template>
              </v-tooltip>
            </v-col>
            <v-col cols="8" md="10">
              <span
                :class="{
                  title: !$vuetify.breakpoint.xsOnly,
                  'subtitle-1': $vuetify.breakpoint.xsOnly,
                  'font-weight-black': true,
                  'grey--text': true
                }"
              >
                {{ $tc('payment', 1) }}
              </span>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              v-if="payment.payment_number !== ''"
            >
              <v-skeleton-loader
                v-if="load"
                type="text"
                tile
              ></v-skeleton-loader>
              <v-text-fieldn
                v-else
                name="payment_number"
                v-model="payment.payment_number"
                color="secondary"
                :label="$t('payment_number')"
                disabled
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <dialtype
                v-model="dialog"
                :to-edit="toEdit"
                ref="dialtype"
                @done="addType"
              />
              <v-skeleton-loader
                v-if="load"
                type="list-item-avatar"
                tile
              ></v-skeleton-loader>
              <ValidationProvider
                vid="type"
                :name="$tc('type', 1)"
                rules="required"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  outlined
                  v-show="!load"
                  v-model="payment.type_payment"
                  autocomplete="off"
                  :error-messages="errors[0]"
                  :items="types"
                  :loading="isLoading"
                  clearable
                  class="secondary--text"
                  item-text="name"
                  item-value="pk"
                  key="type-input"
                  append-icon="fa-search"
                  :prepend-inner-icon="
                    getPermissions([`Invoices.add_paymenttype`]) || isAdmin
                      ? 'fa-plus'
                      : ''
                  "
                  :label="$t('paymenttype')"
                  @click:prepend-inner="open()"
                ></v-autocomplete>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <i-date-picker
                v-model="payment.date"
                :label="$tc('date', 1)"
                color="primary"
                :max="new Date().toISOString().substr(0, 10)"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-skeleton-loader
                v-if="load"
                type="text"
                tile
              ></v-skeleton-loader>
              <ValidationProvider
                vid="amount"
                :name="$tc('amount', 1)"
                :rules="{
                  required: true,
                  min_value: 0.01,
                  max_value: Number(balance)
                }"
                v-slot="{ errors }"
              >
                <i-money
                  v-show="!load"
                  :error-messages="errors[0]"
                  v-model="payment.amount"
                  :label="$tc('amount', 1)"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="3">
              <ValidationProvider
                vid="reference"
                :name="$tc('reference', 1)"
                rules=""
                v-slot="{ errors }"
              >
                <v-text-field
                  name="reference"
                  :error-messages="errors[0]"
                  v-model="payment.reference"
                  color="secondary"
                  :label="$tc('reference', 1)"
                  outlined
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
      </form>
    </ValidationObserver>
  </v-card>
</template>
<script>
import dialtype from '../../components/paymenttype/modal.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    dialtype
  },
  props: {
    balance: [String, Number],
    invoice: [String, Number],
    receivable: [String, Number]
  },
  data() {
    return {
      mode: 1,
      load: false,
      menu: false,
      types: [],
      payments: [],
      searchType: '',
      isLoading: false,
      payment: {
        pk: '',
        payment_number: '',
        invoice: this.invoice,
        type_payment: null,
        amount: 0,
        date: new Date().toISOString().substr(0, 10),
        canceled: false,
        reference: ''
      },
      dialog: false,
      toEdit: null
    }
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters['confirmation/getConfirmationFinished'],
      (val) => {
        if (val === this.$tc('payment', 1)) {
          //this.getInvoicePayment()
          this.$emit('createdPayment')
          this.$store.dispatch('confirmation/confirmationFinished', false)
        }
      }
    )
  },
  beforeDestroy() {
    this.unwatch()
  },
  computed: {
    datemain: {
      set(newValue) {
        if (this.payment.date.length > newValue.length) {
          this.payment.date = null
        }
      },
      get() {
        return this.dateFormat(this.payment.date)
      }
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    paymentBtn() {
      return {
        add: this.getPermissions([`Invoices.add_payments`]) || this.isAdmin,
        edit: this.getPermissions([`Invoices.change_payments`]) || this.isAdmin
      }
    }
  },
  methods: {
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    },
    add() {
      this.mode = 2
      this.payment = {
        pk: '',
        payment_number: '',
        invoice: this.invoice,
        type_payment: null,
        amount: 0,
        date: new Date().toISOString().substr(0, 10),
        canceled: false
      }
      this.$emit('changeMode', this.mode === 2)
    },
    back() {
      this.mode = 1
      this.$emit('changeMode', this.mode === 2)
    },
    addType(evt) {
      this.types.push(evt)
      this.payment.type_payment = evt.pk
    },
    submit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          if (this.receivable !== null && this.receivable !== undefined) {
            try {
              this.load = true
              this.$api.invoice.paymentReceivable.create({
                pk: this.receivable,
                form: this.payment
              })
              this.$toast.success(
                `${this.$tc('payment', 1)} ${this.$tc('created', 2)}`
              )
              this.mode = 1
              this.getInvoicePayment(
                this.receivable !== null && this.receivable !== undefined
                  ? true
                  : undefined
              )
              this.$emit('changeMode', this.mode === 2)
              this.$emit('createdPayment')
              this.getInvoicePayment(
                this.receivable !== null && this.receivable !== undefined
                  ? true
                  : undefined
              )
            } finally {
              this.load = false
            }
          } else {
            this.load = true
            this.$api.invoice.payment
              .create({
                form: this.payment
              })
              .then((response) => {
                this.mode = 1
                this.getInvoicePayment(
                  this.receivable !== null && this.receivable !== undefined
                    ? true
                    : undefined
                )
                this.$emit('changeMode', this.mode === 2)
                this.$emit('createdPayment')
                this.$toast.success(
                  `${this.$tc('payment', 1)} ${this.$tc('created', 2)}`
                )
              })
              .finally(() => (this.load = false))
          }
        }
      })
    },
    canceledPayment(pk, item) {
      this.showConfirmation({
        show: true,
        model: this.$tc('payment', 1),
        title: this.$tc('cancel', 1),
        type: 'error white--text',
        content: this.$t('confirm_delete'),
        alert: `${this.$t('success', {
          model: this.$tc('payment', 1),
          action: this.$tc('canceled', 2)
        })}`,
        confirm: () =>
          this.$api.invoice.payment.edit({
            pk: pk,
            form: { ...item, canceled: true }
          })
      })
    },
    getPaymentType() {
      this.isLoading = true
      this.$api.invoice.type
        .list({})
        .then((response) => {
          this.types = response.data.results
        })
        .finally(() => (this.isLoading = false))
    },
    getInvoicePayment(inReceivable) {
      this.loading = true
      this.$api.invoice.payment
        .show({
          pk: inReceivable !== undefined ? this.receivable : this.invoice,
          opt: {
            params: {
              [inReceivable !== undefined ? 'receivable' : 'invoice']: true
            }
          }
        })
        .then((response) => {
          this.payments = response.data
        })
        .finally(() =>
          this.receivable === null || this.receivable === undefined
            ? (this.loading = false)
            : (this.isLoading = false)
        )
    }
  },
  mounted() {
    this.getPaymentType()
  },
  watch: {
    searchType(val) {
      if (val == null || (typeof val === 'string' && val.length % 2 == 0)) {
        this.getInvoicePayment()
      }
    },
    invoice: {
      immediate: true,
      handler(val) {
        if (val !== undefined && val !== null) {
          this.getInvoicePayment()
        }
      }
    },
    receivable: {
      immediate: true,
      handler(val) {
        if (val !== undefined && val !== null) {
          this.getInvoicePayment(true)
        }
      }
    }
  }
}
</script>
