<template>
  <div>
    <dialassignitem
      ref="dialassignitem"
      @avalaible="avalaible = $event"
      @updateAssignItems="getAssignItems"
      :mode="mode"
      :selectedItem="selectedItem"
      v-model="dialog"
      :to-edit="toEdit"
      @done="reload = true"
      :template="template"
    />
    <div>
      <v-chip class="mr-2" color="primary" @click.stop="open()">
        {{
          $tc(
            mode == 'designer'
              ? 'designers'
              : mode == 'production'
              ? 'productions'
              : mode == 'delivery'
              ? 'driver'
              : 'suborder',
            2
          )
        }}
        {{ qtyAssignItems }}
      </v-chip>
      <v-chip
        @click.stop="open()"
        class="mr-2"
        color="secondary"
        v-for="assignItem in assignItems"
        :key="`${mode}-${assignItem.pk}`"
        pill
      >
        <v-avatar left>
          <i-image
            :value="
              mode == 'driver'
                ? assignItem.suborder.photo
                : assignItem.user_data.photo
            "
            :preview="true"
            :readonly="true"
          />
        </v-avatar>
        {{
          mode == 'driver'
            ? assignItem.suborder.name
            : assignItem.user_data.name
        }}
        <v-chip color="primary" class="ml-1" small>
          {{ $t(assignItem.status) }}
        </v-chip>
      </v-chip>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialassignitem from './dialAssignItem.vue'
export default {
  components: {
    dialassignitem
  },
  props: {
    selectedItem: {
      required: true,
      type: [String, Number]
    },
    mode: {
      default: 'designer',
      type: [String]
    },
    template: {
      type: Number
    }
  },
  data() {
    return {
      assignItems: [],
      avalaible: 0,
      reload: false,
      dialog: false,
      toEdit: null
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog =
        this.getPermissions([`sales.change_assigneduser`]) || this.isAdmin
      this.$refs.dialassignitem.open()
    },
    getAssignItems() {
      this.$api.suborder.assigneduser
        .show({
          pk: this.selectedItem,
          opt: {
            params: {
              mode: this.mode
            }
          }
        })
        .then((response) => {
          this.assignItems = response.data
        })
    }
  },
  computed: {
    qtyAssignItems() {
      return this.assignItems.length
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    })
  },
  watch: {
    selectedItem: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getAssignItems()
        }
      }
    }
  }
}
</script>
