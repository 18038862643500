<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $tc('quantity', 1) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row v-if="delivery > 0">
      <v-col cols="6">
        <v-skeleton-loader
          v-if="load"
          type="list-item-avatar"
          tile
        ></v-skeleton-loader>
        <ValidationProvider
          vid="product"
          :name="$tc('product', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-autocomplete
            outlined
            v-show="!load"
            v-model="quantity.orderProduct"
            autocomplete="off"
            :error-messages="errors[0]"
            :items="items"
            :loading="isLoading"
            clearable
            class="secondary--text"
            item-text="own_prod"
            item-value="pk"
            key="type-input"
            append-icon="fa-search"
            :prepend-inner-icon="'fa-plus'"
            :label="$tc('product', 1)"
            @click:prepend-inner="open()"
          ></v-autocomplete>
        </ValidationProvider>
      </v-col>
      <v-col cols="6">
        <ValidationProvider
          vid="quantity"
          :name="$tc('quantity', 1)"
          :rules="{
            required: true,
            max_value: max
          }"
          v-slot="{ errors }"
        >
          <v-text-field
            autofocus
            :error-messages="errors[0]"
            v-model="quantity.delivered_quantity"
            outlined
            color="secondary"
            :label="$tc('quantity', 1)"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    },
    delivery: {
      type: Number,
      default: 0
    },
    order: {
      type: Number
    },
    cartlist: [Array],
    partialDelivery: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      quantity: {
        orderProduct: '',
        delivered_quantity: 0,
        delivery: this.delivery,
        order: this.order
      },
      edit: false,
      load: false,
      quantity_list: [],
      max: 0
    }
  },
  computed: {
    items() {
      return this.cartlist.filter(
        (element) =>
          this.quantity_list.findIndex((m) => m.orderProduct === element.pk) ===
          -1
      )
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.quantity =
            this.toEdit !== null
              ? { ...this.toEdit }
              : {
                  orderProduct: '',
                  delivered_quantity: 0,
                  delivery: this.delivery,
                  order: this.order
                }
        }
      }
    },
    'quantity.orderProduct': {
      handler(val) {
        this.max =
          this.cartlist[
            this.cartlist.findIndex((m) => m.pk === this.quantity.orderProduct)
          ].quantity
      }
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          const quantity = !this.edit
            ? await this.$api.sale.quantity.create({
                form: this.quantity
              })
            : await this.$api.sale.quantity.edit({
                pk: this.quantity.pk,
                form: this.quantity
              })
          this.$emit('done', quantity.data)
          this.$emit('input', false)
          this.quantity = quantity.data
          this.$toast.success(
            `${this.$tc('quantity', 1)} ${this.$tc(
              this.quantity.pk !== '' ? 'edited' : 'created',
              2
            )}`
          )
          this.getQuantity()
        } finally {
          this.loading = false
        }
      }
    },
    async getQuantity() {
      const quantity = await this.$api.sale.quantity.show({
        pk: this.delivery,
        opt: {
          params: {
            delivery: true
          }
        }
      })
      this.quantity_list = quantity.data
    }
  },
  mounted() {
    this.getQuantity()
  }
}
</script>
