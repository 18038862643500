<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $tc('delivery', 1) })"
    :value="value"
    :load="loading"
    :max-width="'1200px'"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row v-if="cartlist !== undefined">
      <v-col cols="4">
        <ValidationProvider
          vid="given_to"
          :name="$tc('given_to', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            :error-messages="errors[0]"
            v-model="delivery.given_to"
            color="secondary"
            :label="$tc('given_to', 1)"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="4">
        <i-date-picker
          :loading="loading"
          v-model="delivery.delivery_date"
          :label="$tc('date', 1)"
          color="primary"
        />
      </v-col>
      <v-col cols="4" v-if="partialDelivery && delivery.pk === ''">
        <v-btn color="primary" outlined @click="addProduct">
          {{ $t('add', { value: $tc('product', 1) }) }}
          <v-icon small right dark>fa-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" v-if="delivery.pk !== ''">
        <dialQuantity
          v-model="dialog2"
          :to-edit="toEdit2"
          ref="dialquantity"
          :delivery="delivery.pk !== '' ? delivery.pk : 0"
          :cartlist="cartlist"
          :order="order"
          :partialDelivery="partialDelivery"
          @done="reload = true"
        />
        <i-card-list
          v-if="quantityBtn.view"
          class="mt-2 px-2"
          :title="$t('quantity')"
          :headers="headers"
          app="sale"
          api="sale.quantity"
          :method="'show'"
          :requestOpts="{ pk: delivery.pk }"
          :opt="{ delivery: true }"
          :reload.sync="reload2"
          @click:create="quantityBtn.add && partialDelivery ? open() : ''"
          @click:edit="quantityBtn.edit && partialDelivery ? open($event) : ''"
          :dontEdit="!quantityBtn.edit || !partialDelivery"
          :dontCreate="!quantityBtn.add || !partialDelivery"
          :dontRemove="!quantityBtn.delete || !partialDelivery"
        >
          <template v-slot:[`item.orderProduct`]="{ value }">
            <span class="primary--text">
              {{ cartlist[cartlist.findIndex((m) => m.pk == value)].own_prod }}
            </span>
          </template>
        </i-card-list>
        <br /><br />
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import dialQuantity from './dialQuantity.vue'
export default {
  components: {
    dialQuantity
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    },
    order: {
      type: Number
    },
    cartlist: [Array],
    partialDelivery: {
      type: Boolean
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      quantity: 0,
      delivery: {
        pk: '',
        order: this.order,
        given_to: '',
        delivery_date: new Date().toISOString().substr(0, 10)
      },
      edit: false,
      load: false,
      reload2: false,
      dialog2: false,
      toEdit2: null,
      addQuantity: true
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.delivery =
            this.toEdit !== null
              ? { ...this.toEdit }
              : {
                  pk: '',
                  order: this.order,
                  given_to: '',
                  delivery_date: new Date().toISOString().substr(0, 10)
                }
        }
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$tc('product', 1),
          value: 'orderProduct'
        },
        {
          text: this.$t('quantity'),
          value: 'delivered_quantity'
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    quantityBtn() {
      return {
        add:
          this.getPermissions([`sales.add_deliveryquantity`]) || this.isAdmin,
        delete:
          this.getPermissions([`sales.delete_deliveryquantity`]) ||
          this.isAdmin,
        edit:
          this.getPermissions([`sales.change_deliveryquantity`]) ||
          this.isAdmin,
        view:
          this.getPermissions([`sales.view_deliveryquantity`]) || this.isAdmin
      }
    }
  },
  methods: {
    async submit(add) {
      if (!this.partialDelivery) {
        let add = false
      }
      if (!this.loading) {
        try {
          this.loading = true
          const delivery =
            !this.edit && this.delivery.pk === ''
              ? await this.$api.sale.delivery.create({
                  form: this.delivery
                })
              : await this.$api.sale.delivery.edit({
                  pk: this.delivery.pk,
                  form: this.delivery
                })
          if (!add) {
            this.$emit('done', delivery.data)
            this.$emit('input', false)
          }
          this.delivery = delivery.data
          this.$toast.success(
            `${this.$tc('delivery', 1)} ${this.$tc(
              this.delivery.pk !== '' ? 'edited' : 'created',
              2
            )}`
          )
        } finally {
          this.loading = false
        }
      }
    },
    open(item) {
      this.toEdit2 = item === undefined ? null : item
      this.dialog2 = true
    },
    addProduct() {
      this.addQuantity = true
      if (this.delivery.given_to !== '') {
        this.submit(this.addQuantity)
      } else {
        this.$toast.error(`${this.$tc('edit', 1)} ${this.$tc('given_to', 1)}`)
      }
    }
  }
}
</script>
