<template>
  <v-row>
    <v-col cols="12">
      <v-list-item-title>
        <span class="primary--text font-weight-black">
          {{ `${item.code} ${item.name}` }}
        </span>
      </v-list-item-title>
      <v-list-item-title>
        <span class="primary--text font-weight-black">
          {{ $tc('customer', 1) }}:
        </span>
        <span class="primary--text subtitle-2">
          {{ `${item.clientName}` }}
        </span>
      </v-list-item-title>
      <v-list-item-title>
        <span class="primary--text font-weight-black">
          {{ $t('total') }}:
        </span>
        <span class="primary--text subtitle-2">
          {{ `${item.total}` }}
        </span>
      </v-list-item-title>
      <v-list-item-title>
        <span class="primary--text font-weight-black">
          {{ $t('promise_date') }}:
        </span>
        <span class="primary--text subtitle-2">
          {{ `${item.promiseDate}` }}
        </span>
      </v-list-item-title>
      <v-list-item-title>
        <span class="primary--text font-weight-black">
          {{ $t('pending_quantity') }}:
        </span>
        <span class="primary--text subtitle-2">
          {{ `${item.pending}` }}
        </span>
      </v-list-item-title>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  }
}
</script>
