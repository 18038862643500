var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.items),function(item){return _c('v-row',{key:`items-${item.pk}`,staticClass:"infocard mb-1 pl-3 py-2",attrs:{"no-gutters":""}},[(_vm.mode != 'driver')?_c('v-col',{staticClass:"primary--text",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('span',[_vm._v(" "+_vm._s(item.code)+" - "+_vm._s(item.name)+" ")])]):_vm._e(),(_vm.mode == 'driver')?_c('v-list-item-avatar',{attrs:{"size":"60","color":"grey darken-3"}},[_c('i-image',{attrs:{"value":item.photo,"preview":true,"readonly":true}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_vm._v(" "+_vm._s(_vm.mode == 'driver' ? item.first_name : item.own_prod)+" "+_vm._s(_vm.mode == 'driver' ? item.last_name : '')+" ")]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[(
          _vm.mode == 'delivery' ||
          _vm.mode == 'driver' ||
          _vm.mode == 'designer' ||
          (_vm.mode == 'production' &&
            item.leader_status.designer &&
            (item.orderStatus != '' ||
              item.orderStatus == 'design - finished'))
        )?_c('assignuser',{attrs:{"selectedItem":item.pk,"mode":_vm.mode,"template":item.template}}):_vm._e(),(
          _vm.mode == 'production' &&
          item.leader_status.designer == undefined &&
          (item.orderStatus == '' ||
            (item.orderStatus != 'design - finished' &&
              item.orderStatus.includes('design')))
        )?_c('v-alert',{attrs:{"type":"info","outlined":"","color":"primary"}},[_c('span',{staticClass:"primary--text font-weight-black"},[_vm._v(" "+_vm._s(_vm.$t('assignProduction_no'))+" ")])]):_vm._e()],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }