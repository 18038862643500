<template>
  <v-row>
    <v-col cols="12" align="right">
      <v-chip small color="secondary">
        {{ $t('status') }}:
        {{ $t(status) }}
      </v-chip>
      <v-chip small color="info">
        {{ $t('date') }}:
        {{ dateFormat(date) }}
      </v-chip>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    status: {
      required: true,
      type: [String, Number]
    },
    date: {
      required: true,
      type: String
    }
  }
}
</script>
