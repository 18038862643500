<template>
  <i-modal
    :title="modalTitle"
    :value="value"
    :load="loading"
    @input="$emit('input', $event)"
    :max-width="modalMaxWidth"
    dontSave
  >
    <v-card-text>
      <v-row>
        <div class="col-6">
          <h2 class="mb-2 text-center">{{ itemType }}</h2>
          <draggable class="list-group" :list="items" group="people">
            <div class="list-group-item" v-for="item in items" :key="item.id">
              <v-card>
                <!-- Ítems por asignar -->
                <v-list-item class="grow">
                  <v-list-item-avatar size="60" color="grey darken-3">
                    <i-image
                      :value="itemImage(item)"
                      :preview="true"
                      :readonly="true"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-row
                      :justify="$vuetify.breakpoint.smAndDown ? 'start' : 'end'"
                    >
                      <!--
                        Sección delivery, subOrdenes a seleccionar para ser entregadas
                        por cada conductor
                      -->
                      <v-col cols="9" v-if="isDriverMode">
                        <subordersInfo :item="item" />
                      </v-col>
                      <!--
                        Sección subOrden, usuarios disponibles para asignar a una subOrden
                      -->
                      <v-col cols="9" v-if="!isDriverMode">
                        <v-list-item-title>{{
                          `${item.first_name} ${item.last_name}`
                        }}</v-list-item-title>
                      </v-col>
                      <v-col cols="3" align="right">
                        <v-chip x-small color="secondary" class="mx-3">{{
                          itemType
                        }}</v-chip>
                      </v-col>
                    </v-row>
                    <v-row></v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
          </draggable>
          <pagination
            :items="items"
            :perPage.sync="perPage"
            :total="this.total"
            :page="this.page"
            @changePage="changePage"
          />
        </div>

        <div class="col-6">
          <h2 class="mb-2 text-center">{{ assignedTitle }}</h2>
          <draggable
            class="list-group"
            :list="assignItems"
            group="people"
            @change="onUnpublishedChange"
          >
            <div
              class="list-group-item"
              v-for="item in assignItems"
              :key="item.id"
            >
              <v-card v-if="itemHasData(item)">
                <!-- Ítems asignados -->
                <v-list-item class="grow">
                  <v-list-item-avatar size="60" color="grey darken-3">
                    <i-image
                      :value="assignedImage(item)"
                      :preview="true"
                      :readonly="true"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-row
                      :justify="$vuetify.breakpoint.smAndDown ? 'start' : 'end'"
                    >
                      <!-- Sección delivery, subOrdenes a entregar por conductor -->
                      <v-col cols="8" v-if="isDriverMode && item.suborder">
                        <subordersAssignedInfo
                          :item="item.suborder"
                          :client="item.client"
                        />
                      </v-col>
                      <!-- Sección subOrden, usuarios asignados a una subOrden -->
                      <v-col cols="7" v-if="!isDriverMode">
                        <v-list-item-title>{{
                          `${item.user_data.name}`
                        }}</v-list-item-title>
                      </v-col>
                      <v-col cols="4" align="right">
                        <statusChip
                          :status="item.status"
                          :date="item.assignament_date"
                        />
                      </v-col>
                    </v-row>
                    <v-row></v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
          </draggable>
        </div>
      </v-row>
    </v-card-text>
  </i-modal>
</template>

<script>
import { mapActions } from 'vuex'
import draggable from 'vuedraggable'
import subordersInfo from './subordersInfo.vue'
import subordersAssignedInfo from './subordersAssignedInfo.vue'
import pagination from './pagination.vue'
import statusChip from './statusChip.vue'

export default {
  components: {
    draggable,
    subordersInfo,
    subordersAssignedInfo,
    pagination,
    statusChip
  },
  data() {
    return {
      loading: false,
      items: [],
      assignItems: [],
      total: 0,
      perPage: 8,
      page: 1
    }
  },
  props: {
    selectedItem: {
      required: true,
      type: [String, Number]
    },
    mode: {
      default: 'designer',
      type: String
    },
    value: {
      type: Boolean,
      default: false
    },
    template: {
      type: Number
    }
  },
  computed: {
    modalTitle() {
      return this.$t(
        this.mode === 'designer'
          ? 'assignDesigner'
          : this.mode === 'production'
          ? 'assignProduction'
          : this.mode === 'delivery'
          ? 'assignDriver'
          : 'assignSubOrder'
      )
    },
    modalMaxWidth() {
      return this.mode === 'driver' ? '1800px' : '1200px'
    },
    assignedTitle() {
      return this.$t(
        this.mode === 'designer'
          ? 'assignedDesigners'
          : this.mode === 'production'
          ? 'assignedProductions'
          : this.mode === 'delivery'
          ? 'assignedDrivers'
          : 'assignedSubOrders'
      )
    },
    isDriverMode() {
      return this.mode === 'driver'
    },
    itemType() {
      return this.$tc(
        this.mode === 'designer'
          ? 'designers'
          : this.mode === 'production'
          ? 'productions'
          : this.mode === 'delivery'
          ? 'driver'
          : 'suborder',
        2
      )
    }
  },
  methods: {
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    itemImage(item) {
      return this.isDriverMode ? item.thumbnail : item.photo
    },
    assignedImage(item) {
      return this.isDriverMode ? item.suborder.photo : item.user_data.photo
    },
    itemHasData(item) {
      return item.suborder || item.user_data
    },
    /**
     * getItems
     * Caso 1: Método que consulta las subOrdenes que están en estado
     * de orden 'delivery' con el campo delivery activo, que son las
     * que están listas para realizar la entrega
     * Caso 2: Método que consulta los usuarios que están disponibles
     * para ser asignados en la subOrden
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getItems() {
      // Caso 1
      if (this.mode == 'driver') {
        this.itemsAvalaible = await this.$api.sale.product.list({
          opt: {
            params: {
              delivery: true
            }
          }
        })
      } else {
        // Caso 2
        this.itemsAvalaible =
          this.mode === 'designer'
            ? await this.$api.sale.designers({})
            : this.mode === 'production'
            ? await this.$api.department.producers({})
            : this.mode === 'delivery'
            ? await this.$api.sale.drivers({})
            : []
      }
      let deleteList = []
      if (this.mode !== 'designer') {
        this.itemsAvalaible = this.itemsAvalaible.data.results
        this.total = 1
      } else {
        this.total = Math.ceil(this.itemsAvalaible.data.count / this.perPage)
        this.itemsAvalaible = this.itemsAvalaible.data.results
      }
      this.$emit('avalaible', this.itemsAvalaible.length)
      if (this.itemsAvalaible.length > 0) {
        if (this.mode == 'driver') {
          // Eliminación de ítems asignados del caso 1
          this.itemsAvalaible.forEach((element) => {
            const forDelete = this.assignItems.findIndex(
              (item) => item.orderProduct === element.pk
            )
            if (forDelete !== -1) {
              deleteList.push(element.pk)
            }
          })
        } else {
          // Eliminación de ítems asignados del caso 2
          this.itemsAvalaible.forEach((element) => {
            const forDelete = element[
              this.mode === 'designer'
                ? 'designs'
                : this.mode === 'production'
                ? 'deparments'
                : this.mode === 'delivery'
                ? 'drivers'
                : ''
            ].findIndex(
              (item) =>
                this.assignItems.findIndex(
                  (assign) => assign.pk === item.pk
                ) !== -1
            )
            if (forDelete !== -1) {
              deleteList.push(element.pk)
            }
          })
        }
        this.items = this.itemsAvalaible.filter(
          (item) => deleteList.indexOf(item.pk) === -1
        )
      }
    },
    async open() {
      this.loading = true
      try {
        await this.getItemAssigned()
        await this.getItems()
      } catch (err) {
        this.dialog = false
      } finally {
        this.loading = false
      }
    },
    assign(id) {
      let item = {
        orderProduct: this.mode == 'driver' ? id : this.selectedItem,
        user: this.mode == 'driver' ? this.selectedItem : id,
        status: 'in_wait',
        changeDesigner: false,
        mode: this.mode == 'delivery' ? 'driver' : this.mode,
        delivery_date: null, // se recibe desde la app
        assignament_date: new Date().toISOString().substr(0, 10),
        project_leader:
          this.mode !== 'deliver'
            ? this.assignItems.findIndex(
                (element) => element.project_leader === true
              ) === -1
            : false
      }
      if (item.mode == 'designer' && item.project_leader) {
        var audio = new Audio(require('@/../public/sounds/sound.mp3'))
        audio.play()
      }
      if (this.mode == 'delivery' && this.assignItems.length > 1) {
        this.$toast.error(`${this.$t('assignedOneDriver')}`)
      } else {
        // Asignar usuarios tipo, diseñador, productor o driver
        // a una subOrden
        this.$api.suborder.assigneduser
          .create({
            form: item,
            opt: {
              params: {
                template: this.template
              }
            }
          })
          .then((response) => {
            this.getItemAssigned()
            this.getItems()
            this.$emit('updateAssignItems')
            this.$emit('avalaible')
            this.columnsDisabled = false
          })
      }
    },
    remove(pk) {
      this.$api.suborder.assigneduser.remove({ pk }).then((response) => {
        this.getItemAssigned()
        this.getItems()
        this.$emit('updateAssignItems')
        this.$emit('avalaible')
        this.columnsDisabled = false
      })
    },
    /**
     * getItemAssigned
     * Método que consulta los usuarios asignados a la subOrden
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getItemAssigned() {
      let assigned = await this.$api.suborder.assigneduser.show({
        pk: this.selectedItem,
        opt: {
          params: {
            mode: this.mode
          }
        }
      })
      this.assignItems = assigned.data
    },
    async onUnpublishedChange({ added, removed }) {
      this.columnsDisabled = true
      if (added) await this.assign(added.element.pk)
      if (removed) await this.remove(removed.element.pk)
    },
    async changePage() {
      this.getItems()
    }
  },
  mounted() {
    this.getItemAssigned()
    this.getItems()
  },
  watch: {
    perPage() {
      this.getItems()
    }
  }
}
</script>

<style>
.list-group-item {
  border: 0px;
}
.list-group {
  min-height: 400px;
  background-color: #7575755e;
  border-radius: 10px;
}
</style>
