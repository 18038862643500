<template>
  <v-row
    align-self="end"
    id="pagination"
    justify="center"
    :class="{
      'ml-1': $vuetify.breakpoint.xsOnly
    }"
  >
    <v-col v-show="items.length > 0" cols="3" sm="2" align-self="end">
      <v-select
        :items="[4, 8, 12, 16, 20, 24]"
        v-model="perPage"
        :label="$vuetify.lang.t('$vuetify.dataTable.itemsPerPageText')"
      ></v-select>
    </v-col>
    <v-col cols="9" sm="4" class="pt-5" v-show="items.length > 0">
      <v-pagination
        v-model="page"
        :length="total"
        total-visible="7"
      ></v-pagination>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    items: {
      required: true,
      type: Array
    },
    perPage: {
      required: true,
      type: Number
    },
    total: {
      required: true,
      type: Number
    },
    page: {
      required: true,
      type: Number
    }
  }
}
</script>
