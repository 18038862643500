<template>
  <div>
    <v-row
      no-gutters
      v-for="item in items"
      :key="`items-${item.pk}`"
      class="infocard mb-1 pl-3 py-2"
    >
      <v-col
        v-if="mode != 'driver'"
        cols="12"
        sm="6"
        md="4"
        class="primary--text"
      >
        <span> {{ item.code }} - {{ item.name }} </span>
      </v-col>
      <v-list-item-avatar
        v-if="mode == 'driver'"
        size="60"
        color="grey darken-3"
      >
        <i-image :value="item.photo" :preview="true" :readonly="true" />
      </v-list-item-avatar>
      <v-col cols="12" sm="6" md="4">
        {{ mode == 'driver' ? item.first_name : item.own_prod }}
        {{ mode == 'driver' ? item.last_name : '' }}
      </v-col>
      <v-col cols="12" md="4">
        <assignuser
          v-if="
            mode == 'delivery' ||
            mode == 'driver' ||
            mode == 'designer' ||
            (mode == 'production' &&
              item.leader_status.designer &&
              (item.orderStatus != '' ||
                item.orderStatus == 'design - finished'))
          "
          :selectedItem="item.pk"
          :mode="mode"
          :template="item.template"
        />
        <v-alert
          v-if="
            mode == 'production' &&
            item.leader_status.designer == undefined &&
            (item.orderStatus == '' ||
              (item.orderStatus != 'design - finished' &&
                item.orderStatus.includes('design')))
          "
          type="info"
          outlined
          color="primary"
        >
          <span class="primary--text font-weight-black">
            {{ $t('assignProduction_no') }}
          </span>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import assignuser from './infoAssignItem.vue'
export default {
  components: {
    assignuser
  },
  props: {
    items: {
      type: Array
    },
    mode: {
      type: String,
      default: 'designer'
    }
  }
}
</script>
