<template>
  <v-row>
    <v-col cols="12">
      <v-list-item-title class="font-weight-black primary--text my-auto">
        {{ `${item.name}` }}
      </v-list-item-title>
      <v-list-item-title>
        <span class="primary--text font-weight-black">
          {{ $tc('total', 1) }}:
        </span>
        <span class="primary--text subtitle-2">
          {{ `${item.total}` }}
        </span>
      </v-list-item-title>
      <v-list-item-title>
        <span class="primary--text font-weight-black">
          {{ `${$tc('customer', 1)}: ` }}
        </span>
        <span class="primary--text subtitle-2">
          {{ `${client.name}` }}
        </span>
      </v-list-item-title>
      <v-list-item-title>
        <span class="primary--text font-weight-black">
          {{ $t('address') }}:
        </span>
        <span class="primary--text subtitle-2">
          {{ `${client.address.address}` }}
        </span>
      </v-list-item-title>
      <v-list-item-title>
        <span class="primary--text font-weight-black"> {{ $t('city') }}: </span>
        <span class="primary--text subtitle-2">
          {{ `${client.address.city}` }}
        </span>
      </v-list-item-title>
      <v-list-item-title>
        <span class="primary--text font-weight-black">
          {{ $t('state') }}:
        </span>
        <span class="primary--text subtitle-2">
          {{ `${client.address.state}` }}
        </span>
      </v-list-item-title>
      <v-list-item-title>
        <span class="primary--text font-weight-black">
          {{ $t('zip_code') }}:
        </span>
        <span class="primary--text subtitle-2">
          {{ `${client.address.zip_code}` }}
        </span>
      </v-list-item-title>
      <v-list-item-title>
        <span class="primary--text font-weight-black">
          {{ $tc('phone', 1) }}:
        </span>
        <span class="primary--text subtitle-2">
          {{ `${client.phone}` }}
        </span>
      </v-list-item-title>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    },
    client: {
      required: true,
      type: Object
    }
  }
}
</script>
